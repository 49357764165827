import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../model/user';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CoursesService } from '../../../../home/home-page/services/courses.service';
import {
  Collaborator,
  Course,
  Manager,
  NewCourse,
  Professor,
  ProfessorCombo,
  ProfessorFilter,
  ProfessorPage,
  UpdatableCourse,
} from '../../../../home/home-page/home-components/models/data-model';
import { ProfessorService } from 'src/app/modules/home/home-page/services/professor.service';
import { DlgType } from '../../w-notification/models/notification-models';
import { WNotificationService } from '../../w-notification/services/w-notification.service';

@Component({
  selector: 'wave-eduxible-course-modal',
  templateUrl: './course-modal.component.html',
  styleUrls: ['./course-modal.component.scss'],
})
export class CourseModalComponent implements OnInit {
  @Input() title!: string;
  @Input() action!: string;
  @Input() course!: Course;
  @Input() confirmButton!: string;
  filter: ProfessorFilter = {};
  dropdownList: Professor[] = [];
  selectedItems: Professor[] = [];
  selectedItemsResp: Professor[] = [];
  dropdownSettings = {};
  dropdownSettingsResp = {};
  elencoProfessori: Professor[] = [];
  professors: any;
  modalForm!: FormGroup;
  icon: string = '';
  confirmed: boolean = false;

  dropdownListYear = [
    {id: '2022-2023', text: '2022-2023'},
    {id: '2023-2024', text: '2023-2024'},
    {id: '2024-2025', text: '2024-2025'},
    {id: '2025-2026', text: '2025-2026'},
    {id: '2026-2027', text: '2026-2027'},
    {id: '2027-2028', text: '2027-2028'},
    {id: '2028-2029', text: '2028-2029'},
    {id: '2029-2030', text: '2029-2030'},
    {id: '2030-2031', text: '2030-2031'},
    {id: '2031-2032', text: '2031-2032'}
  ];
  selectedItemsYear: any = [];
  dropdownSettingsYear = {};

  intialPath: string = '../../../../../../assets/icons/subjects/';
  initialAlt: string = 'logo';
  images: { path: string; alt: string; id: string; selected: boolean }[] = [
    {
      path: this.intialPath + 'art.png',
      alt: this.initialAlt + ' arte',
      id: 'art',
      selected: false,
    },
    {
      path: this.intialPath + 'biology.png',
      alt: this.initialAlt + ' biologia',
      id: 'biology',
      selected: false,
    },
    {
      path: this.intialPath + 'chemistry.png',
      alt: this.initialAlt + ' chimica',
      id: 'chemistry',
      selected: false,
    },
    {
      path: this.intialPath + 'english.png',
      alt: this.initialAlt + ' inglese',
      id: 'english',
      selected: false,
    },
    {
      path: this.intialPath + 'french.png',
      alt: this.initialAlt + ' francese',
      id: 'french',
      selected: false,
    },
    {
      path: this.intialPath + 'geography.png',
      alt: this.initialAlt + ' geografia',
      id: 'geography',
      selected: false,
    },
    {
      path: this.intialPath + 'german.png',
      alt: this.initialAlt + ' tedesco',
      id: 'german',
      selected: false,
    },
    {
      path: this.intialPath + 'history.png',
      alt: this.initialAlt + ' storia',
      id: 'history',
      selected: false,
    },
    {
      path: this.intialPath + 'italian.png',
      alt: this.initialAlt + ' italiano',
      id: 'italian',
      selected: false,
    },
    {
      path: this.intialPath + 'math.png',
      alt: this.initialAlt + ' matematica',
      id: 'math',
      selected: false,
    },
    {
      path: this.intialPath + 'spanish.png',
      alt: this.initialAlt + ' spagnolo',
      id: 'spanish',
      selected: false,
    },
  ];

  constructor(
    public modal: NgbActiveModal,
    private router: Router,
    public courseService: CoursesService,
    private professorService: ProfessorService,
    private alertService: WNotificationService,
    private _fb: FormBuilder
  ) {}

  ngOnInit() {
    this.dropdownSettingsResp = {
      singleSelection: true,
      idField: 'id',
      textField: 'fullname',
      searchPlaceholderText: 'Cerca...',
      noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
      selectAllText: 'Seleziona tutti',
      unSelectAllText: 'Deseleziona tutti',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'fullname',
      searchPlaceholderText: 'Cerca...',
      noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
      selectAllText: 'Seleziona tutti',
      unSelectAllText: 'Deseleziona tutti',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.dropdownSettingsYear = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      searchPlaceholderText: 'Cerca...',
      noFilteredDataAvailablePlaceholderText: 'Nessun dato trovato',
      selectAllText: 'Seleziona tutti',
      unSelectAllText: 'Deseleziona tutti',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };

    this.filter.active = true; // mostriamo solamente professori attivi
    this.professorService
      .getProfessorListForCombo()
      .subscribe((response: any) => {
        console.log('RESP', response.professors);
        let profAttivi: Professor[] = [];
        for(var i=0; i < response.professors.length; i++) {
          if(response.professors[i].active){
            profAttivi.push(response.professors[i]);
          }
        }
        this.dropdownList = profAttivi;
        this.dropdownList.forEach((element) => {
          element.fullname = element.surname + ' ' + element.name;
        });
        this.elencoProfessori = response.professors;
      });

    this.selectedItems =
      this.course && this.course.collaborators ? this.course.collaborators : [];
    this.selectedItems.forEach((element) => {
      element.fullname = element.surname + ' ' + element.name;
    });
    if (this.course && this.course.manager) {
      this.course.manager.fullname = this.course.manager.surname + ' ' + this.course.manager.name;
      this.selectedItemsResp.push(this.course.manager);
    }
    if(this.course && this.course.year){
      let yearItem: any = {};
      yearItem.id = this.course.year;
      yearItem.text = this.course.year;
      this.selectedItemsYear.push(yearItem);
    }
    if(this.course && this.course.icon){
      this.images.forEach((i) => {
        if (i.id === this.course.icon) {
          i.selected = true;
          this.icon = i.id;
        }
      });
    }

    this.modalForm = this._fb.group({
      denominazione: [this.course ? this.course.name : ''],
      year: [this.course ? this.course.year : ''],
      docente: [this.selectedItemsResp],
      collaborators: [this.selectedItems],
      descrizione: [this.course ? this.course.description : ''],
      icona: [this.course ? this.course.icon : ''],
    });
  }

  selectIcon(idIcon: string, image: any) {
    this.icon = idIcon;
    console.log('icon:' + this.icon);
    /*  this.images.forEach((image) => {
      image.selected = false;
    });
    image.selected = true; */

    this.images.forEach((i) => {
      if (i === image) {
        i.selected = !i.selected;
      } else {
        i.selected = false;
        // this.icon = '';
      }
    });
  }

  onItemSelect(item: any) {
    console.log('item', item);
  }
  onSelectAll(items: any) {
    console.log('item', items);
  }

  submitForm(formValue: any) {
    this.confirmed = true;
    console.log('FORMVALUE', formValue);
    let collaboratorOf: number[] = [];
    let collaborator: Collaborator[] = formValue['collaborators'];
    for (let i = 0; i < collaborator.length; i++) {
      collaboratorOf.push(collaborator[i].id);
    }
    let manager: any = formValue['docente'];

    let year: string = formValue['annoScolastico'];

    switch (this.action) {
      case 'add':
        let newCourse: NewCourse = new NewCourse();
        newCourse.active = true;
        newCourse.name = formValue.denominazione;
        newCourse.manager = formValue['docente']
          ? formValue['docente'][0].id
          : null;
        newCourse.collaborators = collaboratorOf;
        newCourse.description = formValue.descrizione;
        newCourse.icon = this.icon;
        newCourse.year = formValue['year'][0].id;
        console.log(newCourse);
        this.courseService.addCourse(newCourse).subscribe({
          next: (val) => {
            console.log(val, formValue);
            this.alertService.open({
              titolo: "Successo",
              tipo: DlgType.SUCCESS,
              messaggio: `Corso inserito correttamente.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });
            this.modal.close(formValue);
          },
          error: (error: any) => {
            console.log('ERROR MESS', error);
            /*this.alertService.open({
              titolo: "Errore",
              tipo: DlgType.ERROR,
              messaggio: `Errore nell'inserimento del corso.`,
              cancelLabel: "",
              cancelVisible: true,
              okLabel: "",
              codErrore: 0
            });*/
          },
        });
        break;
      case 'edit':
        let updatableCourse: UpdatableCourse = {
          name: formValue['denominazione'],
          description: formValue['descrizione'],
          year: formValue['year'][0].id,
          icon: this.icon,
          collaborators: collaboratorOf,
          manager: manager[0].id,
        };
        console.log(updatableCourse);
        let corsoAggiornato: Course = {
          id: this.course.id,
          name: updatableCourse.name,
          description: updatableCourse.description,
          year: updatableCourse.year,
          icon: updatableCourse.icon,
          collaborators: this.selectedItems,
          manager: this.selectedItemsResp[0],
          active: this.course.active,
          code: this.course.code,
          completedLessons: this.course.completedLessons,
          totalLessons: this.course.totalLessons,
          avgCorrectAnswers: this.course.avgCorrectAnswers,
          totalElements: undefined,
        };
        let ret;
        this.courseService
          .editCourse(this.course.id, updatableCourse)
          .subscribe({
            next: (val) => {
              ret = val;
              console.log('STATUS', ret);
              this.alertService.open({
                titolo: "Successo",
                tipo: DlgType.SUCCESS,
                messaggio: `Corso modificato correttamente.`,
                cancelLabel: "",
                cancelVisible: true,
                okLabel: "",
                codErrore: 0
              });
              this.modal.close(corsoAggiornato);
            },
            error: (error: any) => {
              console.log('ERROR MESS', error);
              /*this.alertService.open({
                titolo: "Errore",
                tipo: DlgType.ERROR,
                messaggio: `Errore nella modifica del corso.`,
                cancelLabel: "",
                cancelVisible: true,
                okLabel: "",
                codErrore: 0
              });*/
            },
          });

        break;
    }
  }
}
