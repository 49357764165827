<header class="container-fluid py-3 px-4 px-md-3 sticky-top header bg-white
  border-bottom">
  <nav class="row align-items-center p-relative">
    <div class="col d-flex justify-content-start ">
      <a class="logo" routerLink="/dashboard">
        <img src="../../../../../assets/icons/logo_eduxible.svg" alt="Logo di
          Eduxible" width="200px" height="40px" />
      </a>
    </div>
    <div class="col col-auto d-flex justify-content-end align-items-center ">
      <ng-template [ngIf]="user.role === 'Student'">
        <button role="link" routerLink="progressi" id="progressi" class="btn
          border-0 d-flex align-items-center text-secondary btn-link text-decoration-none">
          <svg class="icon me-2">
            <use xlink:href="assets/icons/progress.svg#progress"
              href="assets/icons/progress.svg#progress"></use>
          </svg>
          <span class="d-none d-lg-block">I miei progressi</span>
        </button>
      </ng-template>

      <button role="link" routerLink="/informazioni" id="notifiche"
        class="btn border-0 d-flex align-items-center text-secondary btn-link text-decoration-none d-none">
        <svg class="icon me-2">
          <use xlink:href="assets/icons/bell.svg#bell"
            href="assets/icons/bell.svg#bell"></use>
        </svg>
        <span class="d-none d-lg-block">Notifiche</span>
      </button>

      <button role="link" routerLink="/informazioni" id="info"
        class="btn border-0 d-flex align-items-center text-secondary btn-link text-decoration-none d-none">
        <svg class="icon me-2">
          <use xlink:href="assets/icons/question.svg#question"
            href="assets/icons/question.svg#question"></use>
        </svg>
        <span class="d-none d-lg-block">Informazioni</span>
      </button>

      <div class="dropdown">
        <button class="btn border-0 d-flex align-items-center text-secondary
          btn-link text-decoration-none" data-bs-toggle="dropdown" aria-expanded="false">
          <svg class="icon me-2">
            <use xlink:href="assets/icons/user.svg#user"
              href="assets/icons/user.svg#user"></use>
          </svg>
          <span class="d-none d-lg-block">Ciao, {{user.user}}!</span>
        </button>
        <ul class="dropdown-menu position-absolute top-0 end-0 shadow p-3
          bg-body rounded">
          <li class="d-flex d-none">
            <a class="dropdown-item py-2 text-primary text-decoration-none"
              routerLink="/profilo">
              <svg class="icon me-2">
                <use xlink:href="assets/icons/userData.svg#userData"
                  href="assets/icons/userData.svg#userData"></use>
              </svg>
              <span>Il mio profilo</span>
            </a>
          </li>
          <li class="d-flex">
            <button class="dropdown-item py-2 text-primary text-decoration-none" (click)="logOut()" >
              <svg class="icon me-2" >
                <use xlink:href="assets/icons/logout.svg#logout"
                  href="assets/icons/logout.svg#logout"></use>
              </svg>
              <span>Esci</span>
            </button>
          </li>
        </ul>
      </div>

      <ng-template [ngIf]="user.role !== 'Student'" class="d-block d-md-none">
        <button type="button" class="btn text-secondary btn-link navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#collapseMenu" aria-expanded="!isCollapsed"
          aria-controls="collapseMenu"
          *ngIf="user.role !== 'Student'">
          <svg class="icon d-block d-md-none">
            <use xlink:href="assets/icons/list.svg#list"
              href="assets/icons/list.svg#list"></use>
          </svg>
        </button>
      </ng-template>
    </div>
  </nav>
  <div class="collapse d-md-none" id="collapseMenu">
    <div class="collapseMenu d-flex justify-content-center
      navbarScrollingDropdown">
      <wave-eduxible-side-menu class="w-100"></wave-eduxible-side-menu>
    </div>
  </div>
</header>
