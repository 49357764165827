import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Converter } from 'showdown';
import { User } from 'src/app/modules/shared/model/user';
import { Model, StylesManager } from 'survey-core';
import { LessonsService } from '../../../services/lessons.service';
import { QuestionsService } from '../../../services/questions.service';
import {
  Course,
  Lesson,
  LessonDetail,
  TestAnswers,
} from '../../models/data-model';
import { Location } from '@angular/common';
//import "survey-core/defaultV2.min.css";
StylesManager.applyTheme('defaultV2');
@Component({
  selector: 'wave-eduxible-student-test',
  templateUrl: './student-test.component.html',
  styleUrls: ['./student-test.component.scss'],
})
export class StudentTestComponent implements OnInit {
  model!: Model;
  course!: Course;
  icon: string = '';
  utente!: User;
  detail!: LessonDetail;
  elencoLezioni!: Lesson[];
  testQuestionIds: number[] = [];
  jsonSurvey: any = [];
  noQuiz: boolean = false;

  constructor(
    private router: Router,
    private lessonService: LessonsService,
    private testService: QuestionsService,
    public location: Location
  ) {}
  ngOnInit(): void {
    this.utente = JSON.parse(localStorage.getItem('user')!);
    this.course = JSON.parse(localStorage.getItem('course')!);
    this.detail = JSON.parse(localStorage.getItem('lesson')!);
    this.icon = this.course.icon + '.png';
    this.jsonSurvey.title = this.detail.title;
    this.jsonSurvey.startSurveyText = "Inizia il test";
    if((this.detail.secondsPerQuestion && this.detail.secondsPerQuestion > 0) || (this.detail.secondsTest && this.detail.secondsTest > 0)){
      this.jsonSurvey.showProgressBar = 'bottom';
      this.jsonSurvey.showTimerPanel =  "top";
    }    
    //this.jsonSurvey.maxTimeToFinishPage = 30;    
    if(this.detail.secondsPerQuestion && this.detail.secondsPerQuestion > 0) {
      this.jsonSurvey.maxTimeToFinishPage = this.detail.secondsPerQuestion;
    }
    if(this.detail.secondsTest && this.detail.secondsTest > 0) {
      this.jsonSurvey.maxTimeToFinish = this.detail.secondsTest;
    }
    this.jsonSurvey.firstPageIsStarted = true;
    this.jsonSurvey.completedHtml =
      '<h4>Hai risposto correttamente a <b>{correctAnswers}</b> domande su <b>{questionCount}</b>.</h4>';
    let cond: any = [];
    cond.expression = '{correctAnswers} >= ' + this.detail.threshold;
    cond.html =
      '<div class="d-flex mb-3 flex-column align-items-center"><img src="assets/img/party2.gif" title="Animazione di coriandoli da festa""/><h4 class="h1 text-success">Hai superato il test, complimenti!</h4></div><p>Hai risposto correttamente a <b>{correctAnswers}</b> domande su <b>{questionCount}</b>.</p><div class="d-flex justify-content-center mt-5"><a href="/dettaglio_corso" class="btn btn-lg btn-primary"><svg class="icon me-2"><use xlink:href="assets/icons/arrow.svg#arrow" href="assets/icons/arrow.svg#arrow"></use></svg>Torna alle lezioni</a></div>';
    let cond2: any = [];
    cond2.expression = '{correctAnswers} < ' + this.detail.threshold;
    cond2.html =
      '<div class="d-flex mb-3 flex-column align-items-center"><img src="assets/img/broken_robot.png" title="Immagine di un robot rotto""/><h4 class="h1 text-danger">Oh no! Sfortunatamente non hai superato il test.</h4></div><p>Hai risposto correttamente a <b>{correctAnswers}</b> domande su <b>{questionCount}</b>.</p><div class="d-flex justify-content-center mt-5"><a href="/test" class="btn btn-lg btn-primary me-3"><svg class="icon me-2"><use xlink:href="assets/icons/restore.svg#restore" href="assets/icons/restore.svg#restore"></use></svg>Riprova</a><a href="/dettaglio_corso" class="btn btn-lg btn-primary ms-3"><svg class="icon me-2"><use xlink:href="assets/icons/arrow.svg#arrow" href="assets/icons/arrow.svg#arrow"></use></svg>Torna alle lezioni</a></div>';
    this.jsonSurvey.completedHtmlOnCondition = [];
    this.jsonSurvey.completedHtmlOnCondition.push(cond);
    this.jsonSurvey.completedHtmlOnCondition.push(cond2);
    this.testService.getQuestionList(this.course.id, this.detail.id).subscribe({
      next: (res) => {
        console.log(res);
        if (res && res.content.length > 0) {
          let pages: any = [];
          if((this.detail.secondsPerQuestion && this.detail.secondsPerQuestion > 0) || (this.detail.secondsTest && this.detail.secondsTest > 0)){
              let startElements: any = [];
              let startElement: any = [];
              startElement.type = "html";
              startElement.html = "<p style='margin-bottom:20px;'>Stai per iniziare il test.<br>Hai a disposizione " + 
                (this.detail.secondsPerQuestion && this.detail.secondsPerQuestion > 0 ? this.detail.secondsPerQuestion + " secondi per domanda ": "") + 
                (this.detail.secondsTest && this.detail.secondsTest > 0 ? (Math.round(this.detail.secondsTest/60)) + " minuti totali" : "") +
                "<br>In bocca al lupo!</p>";
              startElements.elements = [];
              startElements.elements.push(startElement);
              pages.push(startElements);
          } else {
            let startElements: any = [];
              let startElement: any = [];
              startElement.type = "html";
              startElement.html = "<p style='margin-bottom:20px;'>Stai per iniziare il test.<br><br>In bocca al lupo!</p>";
              startElements.elements = [];
              startElements.elements.push(startElement);
              pages.push(startElements);
          }
          for (var i = 0; i < res.content.length; i++) {
            if(!res.content[i].deleted) {
              let elements: any = [];
              let element: any = [];
              element.type = 'radiogroup';
              element.renderAs = 'standard';
              element.name = res.content[i].id + '';
              if(!((this.detail.secondsPerQuestion && this.detail.secondsPerQuestion > 0) || (this.detail.secondsTest && this.detail.secondsTest > 0))){
                (element.isRequired = true),
                  (element.requiredErrorText =
                    'Devi prima rispondere alla domanda');              
              }
              this.testQuestionIds.push(res.content[i].id);
              element.title = res.content[i].text;
              element.choicesOrder = 'random';
              element.choices = [];
              for (var j = 0; j < res.content[i].answers.length; j++) {
                if(!res.content[i].answers[j].deleted){
                  let answer: any = [];
                  answer.value = res.content[i].answers[j].id;
                  answer.text = res.content[i].answers[j].text;
                  if (res.content[i].answers[j].correct) {
                    element.correctAnswer = res.content[i].answers[j].id;
                  }
                  element.choices.push(answer);
                }
              }
              elements.elements = [];
              elements.elements.push(element);
              pages.push(elements);
            }
          }
          this.jsonSurvey.pages = pages;
          console.log('json for survey', this.jsonSurvey);
          const survey = new Model(this.jsonSurvey);
          
          survey.focusFirstQuestionAutomatic = false;
          survey.focusOnFirstError = false;
          survey.pagePrevText = 'Indietro';
          survey.pageNextText = 'Avanti';
          survey.completeText = 'Completa e invia';
          survey.onTextMarkdown.add(function(survey, options){
              //convert the markdown text to html
              var str = converter.makeHtml(options.text);
              //remove root paragraphs <p></p>
              if (str.startsWith('<p>')) {
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
              }
              //set html
              options.html = str;
          });
          survey.onComplete.add((sender, options) => {
            console.log(JSON.stringify(sender.data, null, 3));
            let answers: number[] = [];
            for (var i = 0; i < this.testQuestionIds.length; i++) {
              if(sender.data[this.testQuestionIds[i]] != null) {
                answers.push(sender.data[this.testQuestionIds[i]]);
              }
            }
            let answerObj: TestAnswers = { answers: answers };
            this.lessonService
              .addTestExecution(this.course.id, this.detail.id, answerObj)
              .subscribe({
                next: (res) => {
                  console.log('Test salvato', res);
                },
              });
          });
          
          //Create showdown markdown converter
          var converter = new Converter();
          survey.onTextMarkdown.add(function(survey, options){
            //convert the markdown text to html
            var str = converter.makeHtml(options.text);
            //remove root paragraphs <p></p>
            str = str.substring(3);
            str = str.substring(0, str.length - 4);
            //set html
            options.html = str;
        });
          this.model = survey;
        } else {
          this.noQuiz = true;
        }
      },
    });
  }

  /*  back(): void {
    this.location.back();
  } */
}
