<button class="btn btn-secondary float-end btn-sm" *ngIf="currentUser.role !== 'Student'"
  (click)="openModalCourse()"><svg class="icon me-2">
    <use xlink:href="assets/icons/add-course.svg#add.course" href="assets/icons/add-course.svg#add-course"></use>
  </svg>Aggiungi corso</button>
<h1 [ngClass]="currentUser.role !== 'Student' ? 'fw-bold mt-5' : 'fw-bold mb-3'">Corsi</h1>
<wave-eduxible-filter-course (formSubmittedCourse)="raiseFormSubmittedCourse($event)"
  *ngIf="currentUser.role !== 'Student'"></wave-eduxible-filter-course>
<div class="row align-items-stretch col-auto">
  <div class="col-xxl-3 col-lg-4 col-md-6 col-12 mb-4" *ngFor="let corso of this.elencoCorsi">
    <div [ngClass]="corso.active ? 'card pb-4' : 'card disabled pb-4'">
      <div>
        <p class="card-label">{{ corso.year }}</p>
      </div>
      <div  [ngClass]="corso.active ? 'card-body text-center' : 'card-body text-center opacity-50'">
        <div class="">
          <div  class="border-bottom pb-3 d-flex justify-content-center align-items-center mb-3">
            <img *ngIf="corso.icon && corso.icon !== ''; else defaultIcon" width="48px" height="48px"
              [src]="'assets/icons/subjects/' + corso.icon + '.png'">
            <ng-template #defaultIcon>
              <img width="48px" height="48px" src="assets/icons/subjects/default.png">
            </ng-template>
            <h4 class="ms-2 mb-0">
              {{ corso.name }}
            </h4>
          </div>
          <p>Responsabile: <strong>{{ corso.manager.name}} {{corso.manager.surname}}</strong>
          </p>
          <p>{{ corso.description }}</p>
          <p class="fs-5 text-center text-danger fw-bold text-uppercase" *ngIf="corso.active === false">
            Non attivo
          </p>
        </div>

        <div class="d-flex align-items-center justify-content-center" *ngIf="currentUser.role === 'Student'">
          <div class="percent">
            <circle-progress [percent]="getPercentage(corso.completedLessons,
              corso.totalLessons)" [radius]="18" [outerStrokeWidth]="5" [innerStrokeWidth]="5"
              [outerStrokeColor]="'#78C000'" [innerStrokeColor]="'#C7E596'" [animation]="true"
              [animationDuration]="1000" [showTitle]="false" [showSubtitle]="false" [showUnits]="false" [space]="-5"
              [outerStrokeColor]="getPercentage(corso.completedLessons,
              corso.totalLessons) > 0 ? '#0154CF' : 'transparent'" [innerStrokeColor]="'#C7D9FC'"
              [renderOnClick]="false">
            </circle-progress>
          </div>
          <span class="small ms-1">{{corso.completedLessons}} di
            {{corso.totalLessons}} lezioni completate</span>
        </div>
        <div>
          <a (click)="goToCourseDetail(corso)" class="course_link cursor-pointer fw-bold text-primary">
            <span class="position-absolute bottom-0 mb-4 me-4">
              <span class="visually-hidden">Vai al corso</span>
              <svg class="icon icon-lg">
                <use xlink:href="assets/icons/arrow.svg#arrow" href="assets/icons/arrow.svg#arrow"></use>
              </svg>
            </span>
          </a>
        </div>
      </div>      
    </div>
  </div>
  <div *ngIf="elencoCorsi && elencoCorsi.length == 0">
    Al momento non ci sono corsi.
  </div>
</div>
<div *ngIf="sizeCurrent <= totCorsi">
  <ngb-pagination (pageChange)="changePage($event)" [collectionSize]="totCorsi" [(page)]="page" [maxSize]="12"
    [rotate]="true" [ellipses]="true" [boundaryLinks]="true" [pageSize]="sizeCurrent">
  </ngb-pagination>
</div>
